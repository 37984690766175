<!-- 头部导航组件 -->
<template>
  <div>
      <nav class="navbar">
          <div class="logo">
              <span @click="go('/index_view/workbench')">伽利略</span>
          </div>
          <ul class="navbar_box">
              <div v-for="(item,index) in navData" :key="index">
                <li
                @click="go(item.path, item)" 
                v-if="item.type == 'normal'" 
                :class="'nav_items ' + (currentPath.indexOf(item.sign) > 0 ? 'active_nav' : '')"
                >
                    <!-- <a class="n_name" :href="item.path">{{item.name}}</a> -->
                    <span class="n_name">{{item.name}}</span>
                </li>
                <li class="line" v-else-if="item.type == 'line'"></li>
              </div>
          </ul>
          <el-dropdown 
          trigger="click" 
          @command="handleCommand" 
          placement="top-start" 
          size="small">
            <div class="user">
              <p>{{userName}}</p>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="uppassword">修改密码</el-dropdown-item>
                <!-- <el-dropdown-item command="upHead">修改头像</el-dropdown-item> -->
                <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
          
      </nav>
  </div>
</template>

<script>
import { FormItem } from "element-ui"

export default {
    data() {
        let un = this.utils.sessionGetStore('userName')
        return {
            userName: un,
            navData: [
                {
                    path: '/index_view/workbench',
                    name: '工作台',
                    type: 'normal',
                    sign: 'index_view'
                },
                {
                    path: '/brand_view/brand_manage',
                    name: '品牌列表',
                    type: 'normal',
                    sign: 'brand_view'
                },
                {
                    type: 'line' // 显示分隔线
                },
                {
                    path: '/service_view/brand_info_aggregation',
                    name: '智慧服务',
                    type: 'normal',
                    sign: 'service_view'
                },
            ]
        }
    },
    created() {
    },
    computed: {
        // 当前路由
        currentPath() {
            return this.$route.path
        }
    },
    methods: {
        go(path, row) {
            if(row && row.name == '智慧服务' && this.$route.query.currentBrand) {
                path = path + `?currentBrand=${this.$route.query.currentBrand}`
            }
            this.$router.push({path})
        },
        // 下拉菜单事件
        handleCommand(command) {
            switch(command) {
                case 'loginout':
                    // 清除浏览器本地存储中的所有数据
                    this.utils.sessionClearStore();
                    this.$router.push('/login');
                    break;
                case 'uppassword':
                    this.$router.push({
                        path:'/cp',
                    })
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    height: 50px;
    background: linear-gradient(-90deg, #0a48d1  0%, #1183fb  100%);
    display: flex;
    .logo {
        width: 180px;
        // background: chartreuse;
        height: 100%;
        text-align: center;
        >span {
            color: #fff;
            font-size: 25px;
            font-weight: 600;
            line-height: 50px;
            cursor: pointer;
        }
    }
    .navbar_box {
        flex: 1;
        // background: chocolate;
        display: flex;
        justify-content: center;

        .nav_items {
            padding: 0 5px;
            // background: cornflowerblue;
            line-height: 50px;
            cursor: pointer;
            
            &:hover {
                background: rgba(0,0,0,.1);
            }
            
            .n_name {
                color: #fff;
            }
        }
        .line {
            display: block;
            width: 2px;
            height: 20px;
            background: rgba(255,255,255,.4);
            margin: 15px;
        }
        .active_nav {
            background: rgba(0,0,0,.1);
        }
    }
    .user {
        width: 120px;
        height: 100%;
        cursor: pointer;
        padding-left: 20px;
        >p {
            color: #fff;
            font-size: 16px;
            line-height: 50px;
            float: left;
            margin-right: 10px;
        }
        >i {
            line-height: 50px;
            color: #fff;
        }
    }
}
</style>