<template>
  <div class="indexMain">
    <!-- 头部导航组件 -->
    <Header></Header>
    <!-- 主体内容 -->
    <div class="mainContent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/layout/header.vue'
export default {
  name: 'Home',
  components: {
    Header,
  },
  created() {
    
  }
}
</script>
<style></style>
